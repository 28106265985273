import React from "react";
import { Helmet } from "react-helmet";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import CopyRight from "../../components/footer/CopyRight";
import ScrollspyNav from "react-scrollspy-nav";

const TermsConditions = () => {
  return (
    <div className="doc-container main-page-wrapper">
      <Helmet>
        <title>
          Terms & Conditions || CasesMatter
        </title>
      </Helmet>
      {/* End Page SEO Content */}

      <Header />
      {/* End Header */}

      {/* =====================================================
				Terms and Condition
			===================================================== */}

      <div className="terms_and_policy">
        <div className="container">
          <ScrollspyNav
            scrollTargetIds={["opt1", "opt2", "opt3", "opt4", "opt5", "opt6", "opt7", "opt8", "opt9", "opt10", "opt11", "opt12"]}
            activeNavClass="active"
            offset={170}
            scrollDuration="300"
          >
            <div className="row align-items-start">
              <div className="col-lg-4 sidenav-sticky">
                <ul className="nav nav-tabs">
                  <li className="nav-item">
                    <a className="nav-link active" href="#opt1">
                      1. Introduction
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#opt2">
                    2.	Consent and Capacity
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#opt3">
                    3.	Intellectual Property Rights
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#opt4">
                    4.	User Responsibilities
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#opt5">
                    5.	Disclaimer
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#opt6">
                    6.	Limitation of Liability
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#opt7">
                    7.	Indemnification
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#opt8">
                    8.	Choice of Law
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#opt9">
                    9.	Dispute Resolution
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#opt10">
                    10.	Severability
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#opt11">
                    11.	Changes to These Terms
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#opt12">
                    12.	Contact Us
                    </a>
                  </li>
                </ul>
              </div>

              <div className="col-lg-8">
                {/* Tab panes */}
                <div className="tab-content ">
                  <div id="opt1">
                    <h2 className="font-gilroy-bold">Terms & Conditions</h2>
                    <div className="update-date">LAST UPDATED: 2/9/2024</div>
                    <h3 id="opt1">1. Introduction</h3>
                    <p>
                    Welcome to the CasesMatter.com Website. This Site is owned and operated by CasesMatter, LLC (“CasesMatter,” “we,” or “us”). These Terms and Conditions (“Terms″) govern your use of our Site,, hereafter referred to as “the Site”. These Terms include information about usage, licensing, and intellectual property. Please read these Terms carefully. By using the Site, you consent to these legally binding Terms as well as our Privacy Policy, which is hereby incorporated.
                    </p>
                    <h3 id="opt2">2. Consent and Capacity</h3>
                    <p>
                    In order to use our services, you must be at least eighteen. Your use of our services is conditioned on your acceptance of these Terms. If you do not agree with these Terms, please discontinue your use of the Site. By using our services, you warrant that you are entering into a binding contract with CasesMatter, LLC. Areas of the Site may also be subject to additional terms. Any additional terms are not intended to replace or supersede these Terms, but rather supplement these Terms in those specific areas. Please take the time to familiarize yourself with the additional conditions of those areas before accessing them. 
                    </p>
                    <h3 id="opt3">3. Intellectual Property Rights</h3>
                    <p>
                    CasesMatter’s trademarks, trade names, logos, and other intellectual property incorporated into the Site are the sole property of CasesMatter, LLC or its licensors and are protected under copyright, trademark, trade secret, and other intellectual property laws. Copying or distributing any material, illustrations, photographs, video, or content from the Site without consent is strictly prohibited. Additionally, any use that constitutes an infringement of any of the above-stated intellectual property rights is prohibited. We, in our sole discretion, reserve the right to remove any content or take any steps deemed appropriate to protect such rights. 
                    </p>
                    <h3 id="opt4">4. User Responsibilities</h3>
                    <p>
                    Users of the Site agree to the following guidelines:
                    </p>
                    <ul>
                      <li>You will keep your username and password confidential. You will not share this information with any other person.</li>
                      <li>You will not use another user’s login and registration information.</li>
                      <li>You will not create fake accounts for the purpose of spamming users or visitors, collecting personal information with or without consent, or any other deceptive practices.</li>
                      <li>You will not circumvent, evade, disable, or otherwise interfere with the security of the Site.</li>
                      <li>You will not infringe the intellectual property rights of others.</li>
                    </ul>
                    <p>
                      Additionally, to access certain portions of the Site, you may be required to provide information. The information you provide must be truthful, accurate, and complete. The information you provide is subject to our Privacy Policy, which is hereby incorporated into these Terms.
                    </p>
                    <h3 id="opt5">5. Disclaimer</h3>
                    <p>
                    THE SITE, ITS CONTENT, AND ALL THE MATERIALS ARE “AS IS” AND “AS AVAILABLE” WITHOUT WARRANTY OR GUARANTEE. YOU ACCESS THE SITE AT YOUR OWN RISK UNDERSTANDING THAT CASESMATTER, LLC DISCLAIMS ANY AND ALL WARRANTIES, INCLUDING, BUT NOT LIMITED TO, EXPRESS OR IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT. CASESMATTER, LLC WILL NOT BE RESPONSIBLE FOR OR LIABLE FOR ANY DAMAGE THAT MAY ARISE FROM YOUR USE OF THIS SITE. CASESMATTER, LLC DISCLAIMS ANY AND ALL WARRANTIES PERTAINING TO THE ACCURACY AND USEFULNESS OF THE MATERIALS, CONTENT, SOFTWARE, OR SERVICES PROVIDED VIA THE SITE. CASESMATTER, LLC MAKES NO PROMISES OR REPRESENTATIONS THAT OUR SOFTWARE IS FREE FROM VIRUSES, MALWARE, OR ANY OTHER HARMFUL COMPONENTS, OR THAT THE SITE WILL BE AVAILABLE WITHOUT INTERRUPTION.
                    </p>
                    <p>
                    THIS SECTION APPLIES TO YOU TO THE FULL EXTENT ALLOWED BY APPLICABLE LAW. 
                    </p>
                    <h3 id="opt6">6. Limitation of Liability</h3>
                    <p>
                    TO THE MAXIMUM EXTENT PROVIDED BY APPLICABLE LAW, CASESMATTER, LLC DOES NOT ACCEPT LIABILITY FOR LOSS OR DAMAGE OF ANY KIND, BE IT DIRECT, INDIRECT, CONSEQUENTIAL, SPECIAL, EXEMPLARY, INCIDENTAL, ACTUAL, PUNITIVE, OR OTHERWISE, WHETHER IT ARISES FROM PERSONAL INJURY, FINANCIAL LOSS, DATA LOSS, OPPORTUNITY LOSS, THIRD-PARTY USE OR MISUSE, AGGREGATE SERVICE, PERSONAL DISSATISFACTION, OR ANY OTHER DAMAGE RESULTING FROM YOUR USE OF THE SITE. THE FULL ASSUMPTION OF RISK AND THEREFORE RESPONSIBILITY LIES WITH YOU, THE USER AND VISITOR. CASESMATTER, LLC, ITS DIRECTORS, SHAREHOLDERS, EMPLOYEES, REPRESENTATIVES, AGENTS, SUBSIDIARIES, ASSIGNORS, AND LICENSORS WILL NOT BE HELD LIABLE IN ANY EVENT. FOR JURISDICTIONS IN WHICH STATUTORY LAW PROHIBITS THE LIMITATION OF CERTAIN TYPES OF LIABILITY RELATED TO THE GROSS NEGLIGENCE OF A PARTY, CASESMATTER, LLC’S LIABILITY IS LIMITED TO THE FULL EXTENT ALLOWED BY APPLICABLE LAW.
                    </p>
                    <h3 id="opt7">7. Indemnification</h3>
                    <p>
                    You agree to indemnify and hold harmless CasesMatter, LLC, its directors, shareholders, employees, representatives, agents, subsidiaries, assignors, and licensors from and against any lawsuits, disputes, claims, proceedings, demands, costs, or expenses related to or stemming from any use of the Site or your breach of these Terms and any other policies incorporated into this agreement
                    </p>
                    <h3 id="opt8">8. Choice of Law</h3>
                    <p>
                    These Terms and any disputes arising from the use of THE SITE are governed by and construed according to the law of the state of Kansas. Each party agrees to submit to the courts of the state of Kansas and that the state of Kansas has personal jurisdiction over the matter.
                    </p>
                    <h3 id="opt9">9. Dispute Resolution</h3>
                    <p>
                    We believe that it is beneficial to all parties to quickly resolve any potential disputes as efficiently and cost-effectively as possible. In line with this belief, you agree that any disputes arising from your use of the Site will be handled and resolved according to the provisions of these Terms, unless otherwise explicitly stated. If a dispute arises, you agree to first contact us and attempt to resolve any such issues informally. If informal attempts fail, you agree to submit any claim, dispute, or controversy to binding arbitration.
                    </p>
                    <p>
                    YOU AGREE TO WAIVE ANY AND ALL RIGHTS TO A JURY TRIAL YOU MAY HAVE IN ANY AND ALL JUDICIAL PROCEDURES AND PROCEEDINGS RELATED TO ANY DISPUTE ARISING FROM YOUR USE OF THE SITE UNDER THESE TERMS.
                    </p>
                    <p>
                    ALL PARTIES WAIVE THEIR RIGHTS TO PRESENT CLAIMS IN A CLASS ACTION SUIT.
                    </p>
                    <h3 id="opt10">10. Severability</h3>
                    <p>
                    If any portion of these Terms is deemed unenforceable, void, or invalid for any reason, the remaining provisions of these Terms will remain unaffected and will not be considered unenforceable, void, or invalid. They will maintain the full force of law to the extent possible. 
                    </p>
                    <h3 id="opt11">11. Changes to These Terms</h3>
                    <p>
                    We may update these Terms from time to time. If any updates are made to these Terms, they will be posted here. Additionally, if material changes are made, we will notify you via a notice posted on the Site, and registered users will receive an additional email. Please check this page frequently for updates.
                    </p>
                    <h3 id="opt12">12. Contact Us</h3>
                    <p>
                    If you have any questions, comments, or concerns about these Terms or our services, please contact us via email at support@casesmatter.com, or by mail at 4301 Main St., Parsons, Kansas 67357.
                    </p>
                  </div>
                </div>
                {/*  /.tab-content */}
              </div>
            </div>
          </ScrollspyNav>
        </div>
      </div>

      <footer className="theme-footer-one pt-130 md-pt-70">
        <div className="top-footer">
          <div className="container">
            <Footer />
          </div>
          {/* /.container */}
        </div>
        {/* /.top-footer */}

        <div className="container">
          <div className="bottom-footer-content">
            <CopyRight />
          </div>
          {/*  /.bottom-footer */}
        </div>
      </footer>
      {/* /.theme-footer-one */}
    </div>
  );
};

export default TermsConditions;
