import React from "react";
// Route Specific
import { Routes, Route } from "react-router-dom";
import ScrollTopBehaviour from "../components/ScrollTopBehaviour";

// All HOME PAGE ROUTES
import Main from "../views/all-home-pages/Main";
import ComingSoon from "../views/all-home-pages/ComingSoon";
import Contact from "../views/inner-pages/Contact";
import TermsConditions from "../views/inner-pages/TermsConditions";
import CookiePolicy from "../views/inner-pages/CookiePolicy";
import PrivacyPolicy from "../views/inner-pages/PrivacyPolicy";

// All INNER PAGES ROUTES START FROM BELLOW

// Not Found Page
import NotFound from "../views/NotFound";

const AllRoutes = () => {
  return (
    <>
      <ScrollTopBehaviour />
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/coming-soon" element={<ComingSoon />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/terms-conditions" element={<TermsConditions />} />
        <Route path="/cookie-policy" element={<CookiePolicy />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="*" element={<NotFound />} />

        {/* NotFound Route */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
};

export default AllRoutes;
