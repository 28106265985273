import React from "react";

const FeatureContent = [
  {
    icon: "61",
    meta: "Information Access",
    subTitle: `Imagine if all staff have instantaneous access to the same information.`,
  },
  {
    icon: "60",
    meta: "Improve Communications",
    subTitle: `Front-line staff can answer client questions without having to 'get the file'.`,
  },
  {
    icon: "63",
    meta: "Assign Tasks",
    subTitle: `Create tasks for staff, track time and invoice on your billing schedule.`,
  },
];

const FeaturesEight = () => {
  return (
    <div className="row justify-content-center">
      {FeatureContent.map((val, i) => (
        <div
          className="col-lg-4 col-md-6"
          key={i}
          data-aos="fade-up"
          data-aos-duration="1200"
        >
          <div className="block-style-thirteen">
            <div className="icon">
              <img src={`images/icon/${val.icon}.svg`} height="70" alt="icon" />
            </div>
            <div className="title font-rubik">{val.meta}</div>
            <p>{val.subTitle}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default FeaturesEight;
