import React from "react";
import { Link } from "react-router-dom";

const MegaMenu = () => {
  return (
    <ul className="navbar-nav">
      <li className="nav-item dropdown">
        <a className="nav-link dropdown-toggle" href="/#product" data-toggle="dropdown">
          Product
        </a>
        <ul className="dropdown-menu">
          <li className="dropdown-submenu dropdown">
            <a
              href="/#mattermanagement"
              className="dropdown-item"
            >
              Matter Management
            </a>
          </li>
          <li className="dropdown-submenu dropdown">
            <a
              href="/#tasking"
              className="dropdown-item"
            >
              Tasking
            </a>
          </li>
          <li className="dropdown-submenu dropdown">
            <a
              href="/#billing"
              className="dropdown-item"
            >
              Billing
            </a>
          </li>
        </ul>
        {/* /.dropdown-menu */}
      </li>
      {/* End li */}
      <li className="nav-item">
        <a href="/#whyus" className="nav-link">
          Why CasesMatter?
        </a>
      </li>
      {/* End li */}
      <li className="nav-item dropdown">
        <a href="/#pricing" className="nav-link">
          Pricing
        </a>
      </li>
      {/* End li */}
      <li className="nav-item dropdown">
        <Link to="/contact" className="nav-link">
          Contact Us
        </Link>
      </li>
      {/* End li */}
      <li className="nav-item dropdown">
        <Link to={process.env.REACT_APP_CONTROL_URL} className="nav-link">
          Join Us
        </Link>
      </li>
    </ul>
  );
  // End navbar nav mega menu main
};

export default MegaMenu;
