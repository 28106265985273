import React from "react";

const linksFooterContent = [
  {
    itemName: "Home",
    routePath: "#",
  },
  {
    itemName: "Product",
    routePath: "#product",
  },
  {
    itemName: "Why CasesMatter",
    routePath: "#whyus",
  },
  {
    itemName: "Pricing",
    routePath: "#pricing",
  },
  {
    itemName: "Contact Us",
    routePath: "/contact",
  },
];

const legalFooterContent = [
  {
    itemName: "Terms & conditions",
    routePath: "/terms-conditions",
  },
  {
    itemName: "Privacy policy",
    routePath: "/privacy-policy",
  },
  {
    itemName: "Cookie policy",
    routePath: "/cookie-policy",
  },
];

const Footer = () => {
  return (
    <div className="row justify-content-start">
      <div className="col-xl-4 col-lg-3 col-12 footer-about-widget">
        <div className="logo">
          <a href="index-event.html">
              <img src="images/logo/CasesMatter-Logo.svg" height="40" width="248" alt="CasesMatter" />
          </a>
        </div>
        <ul className="font-rubik mt-10">
          <li>
            <a href="mailto:sales@casesmatter.com">sales@casesmatter.com</a>
          </li>
          <li>
            <a href="tel:+16207177668">+1 (620) 717-7668</a>
          </li>
        </ul>
      </div>
      {/* /.footer-list */}

      <div className="col-lg-3 col-md-4 footer-list">
        <h5 className="footer-title font-slab">Links</h5>
        <ul>
          {linksFooterContent.map((list, i) => (
            <li key={i}>
              <a href={list.routePath}>{list.itemName}</a>
            </li>
          ))}
        </ul>
      </div>

      {/* /.footer-list */}
      <div className="col-lg-3 col-md-4 footer-list">
        <h5 className="footer-title font-slab">Legal</h5>
        <ul>
          {legalFooterContent.map((list, i) => (
            <li key={i}>
              <a href={list.routePath}>{list.itemName}</a>
            </li>
          ))}
        </ul>
      </div>
      {/* /.footer-list */}

    </div>
  );
};

export default Footer;
