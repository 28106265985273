import React from "react";
import { Helmet } from "react-helmet";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import CopyRight from "../../components/footer/CopyRight";
import ScrollspyNav from "react-scrollspy-nav";

const CookiePolicy = () => {
  return (
    <div className="doc-container main-page-wrapper">
      <Helmet>
        <title>
          Cookie Policy || CasesMatter
        </title>
      </Helmet>
      {/* End Page SEO Content */}

      <Header />
      {/* End Header */}

      {/* =====================================================
				Terms and Condition
			===================================================== */}

      <div className="terms_and_policy">
        <div className="container">
          <ScrollspyNav
            scrollTargetIds={["opt1", "opt2", "opt3", "opt4", "opt5", "opt6"]}
            activeNavClass="active"
            offset={170}
            scrollDuration="300"
          >
            <div className="row align-items-start">
              <div className="col-lg-4 sidenav-sticky">
                <ul className="nav nav-tabs">
                  <li className="nav-item">
                    <a className="nav-link active" href="#top">
                      Top
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#opt1">
                      1. Cookies Explained
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#opt2">
                      2. Third-Party Cookies
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#opt3">
                      3. Consent
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#opt4">
                      4. Disabling and Deleting Cookies
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#opt5">
                      5. Updates to This Policy
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#opt6">
                      6. Contact Us
                    </a>
                  </li>
                </ul>
              </div>

              <div className="col-lg-8">
                {/* Tab panes */}
                <div className="tab-content">
                  <div>
                    <h2 className="font-gilroy-bold">Cookie Policy</h2>
                    <div className="update-date">LAST UPDATED: 2/9/2024</div>
                    <p>
                    At CasesMatter, LLC, we are committed to transparent communication about the way our website collects information from users. Our Privacy Policy, located at www.casesmatter.com/privacy, describes and governs our data collection and processing practices. This Cookie Policy specifically addresses how we use various technologies. The cookie technologies covered in this policy include cookies, flash cookies, pixels, web beacons, and other related data files customarily used by most websites today.
                    </p>
                    <h3 id="opt1">1. Cookies Explained</h3>
                    <p>
                      Cookies are small, encrypted data files stored on your computer’s hard drive when you visit a website. They allow a site to capture data you provide on the site and store it for later retrieval. Cookies are most often used to capture information about things like your user preferences and decisions. By storing cookies, website owners are able to understand your online practices and create time-saving options to enhance your overall browsing experience. For example, if you opt out of signing up for our email list, the cookies that our website has stored on your computer recall that information so that you do not receive another request to join our list moments after your initial refusal.
                    </p>
                    <p>
                      We use cookies on our site for the following purposes: 
                      <ul>
                        <li>store and manage user account datay</li>
                      </ul>
                    </p>
                    <h3 id="opt2">2. Third-Party Cookies</h3>
                    <p>
                      The cookies stored when you visit our site may also include third-party cookies. Third-party cookies are stored by other parties and are used primarily to track a user’s browsing history. Third parties may also use cookies to provide advertising to you across various sites.
                    </p>
                    <h3 id="opt3">3. Consent</h3>
                    <p>
                      When you click the “I accept” button on the cookie banner that appears on our website, you consent to our use of cookies as described in this policy. If you do not consent to our use of cookies, you must stop using our site or manually disable them via your browser’s settings.
                    </p>
                    <h3 id="opt4">4. Disabling and Deleting Cookies</h3>
                    <p>
                      You can manage, block, or delete cookies by adjusting your cookie preferences in your browser settings. However, please note that disabling certain cookies could significantly impact your user experience. Specific instructions for managing cookies in your particular browser are provided at the links below:
                      <ul>
                        <li><a href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=en">Google Chrome</a></li>
                        <li><a href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer">Firefox</a></li>
                        <li><a href="https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies">Internet Explorer</a></li>
                        <li><a href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac">Safari</a></li>
                      </ul>
                    </p>
                    <h3 id="opt5">5. Updates to This Policy</h3>
                    <p>
                      We may update this policy from time to time. Please check this page frequently for updates.
                    </p>
                    <h3 id="opt6">6. Contact Us</h3>
                    <p>
                      If you have any questions, comments, or concerns about this Privacy Policy or our services, please contact us via email at support@casesmatter.com, or by mail at 4301 Main St., Parsons, Kansas 67357.
                    </p>
                  </div>
                </div>
                {/*  /.tab-content */}
              </div>
            </div>
          </ScrollspyNav>
        </div>
      </div>

      <footer className="theme-footer-one pt-130 md-pt-70">
        <div className="top-footer">
          <div className="container">
            <Footer />
          </div>
          {/* /.container */}
        </div>
        {/* /.top-footer */}

        <div className="container">
          <div className="bottom-footer-content">
            <CopyRight />
          </div>
          {/*  /.bottom-footer */}
        </div>
      </footer>
      {/* /.theme-footer-one */}
    </div>
  );
};

export default CookiePolicy;
