import React from "react";
import { Helmet } from "react-helmet";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import CopyRight from "../../components/footer/CopyRight";
import ScrollspyNav from "react-scrollspy-nav";

const PrivacyPolicy = () => {
  return (
    <div className="doc-container main-page-wrapper">
      <Helmet>
        <title>
          Privacy Policy || CasesMatter
        </title>
      </Helmet>
      {/* End Page SEO Content */}

      <Header />
      {/* End Header */}

      {/* =====================================================
				Terms and Condition
			===================================================== */}

      <div className="terms_and_policy">
        <div className="container">
          <ScrollspyNav
            scrollTargetIds={["opt1", "opt2", "opt3", "opt4", "opt5", "opt6", "opt7", "opt8", "opt9", "opt10"]}
            activeNavClass="active"
            offset={170}
            scrollDuration="300"
          >
            <div className="row align-items-start">
              <div className="col-lg-4 sidenav-sticky">
                <ul className="nav nav-tabs">
                  <li className="nav-item">
                    <a className="nav-link active" href="#top">
                      Top
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link active" href="#opt1">
                    Introduction
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link active" href="#opt2">
                    1. Information We Collect
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#opt3">
                    2. How We Collect Your Information
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#opt4">
                    3. How We Use Your Information
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#opt5">
                    4. How We Share Your Information
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#opt6">
                    5. How We Protect Your Information
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#opt7">
                    6. Age Limits for Children
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#opt8">
                    7. Correcting or Updating Your Information
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#opt9">
                    8. Specific California Resident Rights
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#opt10">
                    9. Updates to This Policy
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#opt11">
                    10. Contact Us
                    </a>
                  </li>
                </ul>
              </div>

              <div className="col-lg-8">
                {/* Tab panes */}
                <div className="tab-content ">
                  <div>
                    <h2 className="font-gilroy-bold">Privacy Policy</h2>
                    <div className="update-date">LAST UPDATED: 2/9/2024</div>
                    <h3 id="opt1">Introduction</h3>
                    <p>
                      Welcome to the CasesMatter.com Website. This site is owned and operated by CasesMatter, LLC (“CasesMatter,” “we,” or “us”). This Privacy Policy governs your use of our website, app, email, and third-party partners or vendors, hereafter referred to as the “Site.”
                    </p>
                    <p>
                      We understand that your data is important to you and that you care about how your information is collected and used. We have compiled the information below to explain how we gather, use, and store your information. Please read through this policy carefully. Your use of this Site indicates your consent. 
                    </p>
                    <p>
                      This Privacy Policy is organized into the following sections:
                    </p>
                    <ol type="1">
                      <li>1. Information we collect</li>
                      <li>2. How we collect your information</li>
                      <li>3. How we use your information</li>
                      <li>4. How we share your information</li>
                      <li>5. How we protect your information</li>
                      <li>6. Age limits for children</li>
                      <li>7. Correcting or updating your information</li>
                      <li>8. Specific California resident rights</li>
                      <li>9. Updates to this policy</li>
                      <li>10. Contact information</li>
                    </ol>
                    <h3 id="opt2">1. Information We Collect</h3>
                    <p>
                      <div className="fst-italic fw-bold">Defining Personally Identifiable Information</div>
                      Personally identifiable information is any information collected by us or provided by you that with reasonable efforts could be used to identify you. Personally identifiable information includes the information you give to us directly, the information we automatically collect, and when applicable, information supplied to us by third parties. 
                    </p>
                    <p>
                      Specifically, we may collect the following types of information:
                    </p>
                    <ul>
                      <li>information that directly identifies you such as your name, age, postal address, email address, and phone number;</li>
                      <li>order and billing information such as payment information, shipping address, and billing address;</li>
                      <li>information from surveys, contests, and questionnaires such as your age, date of birth, gender, interests, lifestyle, income, race, hobbies, employment history, educational history, and social media details;</li>
                      <li>information you share in our public forums through photographs, comments, uploads, feedback, reviews, testimonials, and original content;</li>
                      <li>information regarding your requests for appointments, services, or assistance;</li>
                      <li>information about your geographic location;</li>
                      <li>information about your purchases on our Site;</li>
                      <li>analytics about you as a user such as the type of device you use, your internet connection, and your preferences; and</li>
                      <li>analytics about you as a user such as the type of device you use, your internet connection, your phone number, and your preferences, which may include additional metadata like photos, address information, video, and audio files on your mobile phone with your permission.</li>
                    </ul>
                    <p>
                      Please note that the Site does access geolocation information about where you are when using the device. 
                    </p>
                    <h3 id="opt3">2. How We Collect Your Information</h3>
                    <p>
                      We collect this information in the following ways: directly from you; automatically using technology like cookies, web bugs, and other web technologies; and directly from our partners and vendors.
                    </p>
                    <p>
                      <div className="fst-italic fw-bold">Information Directly From You</div>
                      As you use the Site, information we collect directly from you includes information provided in response to our direct request for your name, age, postal address, email address, and phone number. We may also collect information that you provide in correspondence with our company. In addition, when you fill out a form, complete a survey, sign up for an email list, make a purchase, share content, leave a comment on one of our boards, or engage in other similar interactions, you are directly providing us with your personal information.
                    </p>
                    <p>
                      If you decide to register for an account with us to access additional resources, services, and goods on our Site, you will be asked to complete a form requesting information such as your name and email address in order to create a user account. You will be given the option to upload a photograph for your account.
                    </p>
                    <p>
                      Your information may become public if you share content or opinions on public areas of the Site. Any contributions you post as a user are at your own risk. As a company, we may restrict access to certain portions of our site, however, we strongly encourage you to carefully consider what you share publicly and with whom you share it. If you share information publicly, we cannot guarantee that third parties will not have access to the information.
                    </p>
                    <p>
                      <div className="fst-italic fw-bold">Information Automatically Collected Using Technology</div>
                      Our Site uses cookies, web beacons, log files, and similar technologies to automatically collect information about who you are and how you use our Site. Cookies are small files stored on your computer that contain information and are used to track information as you navigate different sites. These technologies work together and collect information about
                    </p>
                    <ul>
                      <li>the type of device you are using,</li>
                      <li>browsers you prefer,</li>
                      <li>domain names you may access,</li>
                      <li>your activities on the device, and</li>
                      <li>your IP address.</li>
                    </ul>
                    <p>
                      These technologies do not collect personally identifiable information but may be used in conjunction with other information gathered. Over time, we may also collect information about your other activities online and combine this information for the purposes of behavioral profiling.
                    </p>
                    <p>
                      <div className="fst-italic fw-bold">Do Not Track</div>
                      Browsers and devices allow individuals to send “do not track” signals to websites. At this time, we do not take actions to respond to “do not track” signals.
                    </p>
                    <p>
                      <div className="fst-italic fw-bold">Information From Third Parties</div>
                      In some instances, we may receive information from our third-party partners and vendors about our users. We may also incorporate third-party marketing and analytic services, such as Google Analytics, to help us analyze how our Site is being used. 
                    </p>
                    <p>
                      Our Privacy Policy only governs CasesMatter’s use of your information; however, we may use these third-party services to analyze and enhance your experience.
                    </p>
                    <h3 id="opt4">3. How We Use Your Information</h3>
                    <p>
                      We collect and use information from you for the following purposes:
                    </p>
                    <ul>
                      <li>to operate and maintain our site</li>
                      <li>to present our content to you through our site</li>
                      <li>to communicate with you</li>
                      <li>to fulfill our obligations to you as presented when you provided the information</li>
                      <li>to notify you about changes to our site</li>
                      <li>to enhance and optimize your experience</li>
                      <li>for any other purpose within your consent</li>
                    </ul>
                    <p>
                      In some instances, we also use information to contact you about goods and services that you may find valuable; however, if you would not like to receive information about ongoing promotions or tools we believe are relevant to you, please contact us at support@casesmatter.com.
                    </p>
                    <p>
                      <div className="fst-italic fw-bold">Targeted Advertising</div>
                      When you visit our site, the data collected by cookies during your visit may also be used to advertise to you. The analytics from your visit help us understand what may be of interest to you. You can opt out of or modify these advertising methods by visiting the following sites:
                    </p>
                    <ul>
                      <li><a href="https://www.google.com/settings/ads/anonymous">https://www.google.com/settings/ads/anonymous</a></li>
                      <li><a href="https://www.facebook.com/settings/?tab=ads">https://www.facebook.com/settings/?tab=ads</a></li>
                    </ul>
                    <p>
                      You can also visit <a href="https://optout.aboutads.info">https://optout.aboutads.info</a> to control the collection of web data on your computer and to opt out of additional targeted marketing.
                    </p>
                    <h3 id="opt5">4. How We Share Your Information</h3>
                    <p>
                      Though we do not sell or disclose the personal information we collect in the ordinary course of our business, we may share your data with third parties in accordance with the purposes described above. Additionally, in the course of business, if we buy or sell business assets or participate in a company sale, merger, reorganization, or dissolution, the data we have collected may be part of such business transfers. 
                    </p>
                    <p>
                      Below is a nonexhaustive list of some of the partners and vendors we use to provide our services and conduct business: 
                    </p>
                    <ol>
                      <li>Stripe. Stripe is a payment processor for online businesses. Its policies regarding data collection are outlined here: <a href="https://stripe.com/privacy">https://stripe.com/privacy</a>.</li>
                      <li>Zoho Books. Zoho Books is an online accounting software, and you can visit its site at <a href="https://www.zohobooks.com">https://www.zohobooks.com</a>.</li>
                    </ol>
                    <p>
                      This list is subject to change at our discretion. If you would like more information about our third-party partners, please visit their specific privacy policy pages that describe their practices. 
                    </p>
                    <h3 id="opt6">5. How We Protect Your Information</h3>
                    <p>
                      As a company, the protection of your information is important to us. We have designed and adopted technological and organizational procedures to safeguard your data. It is important to note that while we make efforts to protect your data, security measures are not impenetrable. As a result, we cannot guarantee the absolute security of any information collected or transmitted to our Site. By using our Site, you consent to the transmission of your data at your own risk. 
                    </p>
                    <p>
                      Additionally, if you have registered for an account on our Site, you should not share your password or username with any other third parties. It is your responsibility to keep this information confidential.
                    </p>
                    <h3 id="opt7">6. Age Limits for Children</h3>
                    <p>
                      Children under the age of eighteen are prohibited from accessing the Site. Additionally, we do not use our site to knowingly collect personal information from children. Please contact us if you learn or have reason to suspect that a child under the age of eighteen is using the Site. We will take prompt action to delete any personal information about the child. 
                    </p>
                    <h3 id="opt8">7. Correcting, Updating and Deleting Your Information</h3>
                    <p>
                      If you discover that inaccurate information about you has been collected, or if you would like to update or delete the information we currently have, please contact us via email at support@casesmatter.com, or by mail at CasesMatter, LLC, 4301 Main St., Parsons, Kansas 67357. 
                    </p>
                    <h3 id="opt9">8. Specific California Resident Rights</h3>
                    <p>
                      This section is only applicable for individuals who reside in California and should be read in conjunction with our entire Privacy Policy. If you are a California resident, you have the right to
                    </p>
                    <ul>
                      <li>obtain additional information about “personal information” (as defined in the California Consumer Privacy Act (CCPA));</li>
                      <li>request that we disclose certain information collected, used, disclosed, and sold about you;</li>
                      <li>request deletion of your personal information; and</li>
                      <li>opt out of the sale of your personal information.</li>
                    </ul>
                    <p>
                      The information covered by these specific rights is information that could be reasonably linked to you. It excludes information that cannot be reasonably linked to you.
                    </p>
                    <p>
                      To help you better understand our practices, the chart below describes the categories of personal information collected, the sources of this information, how we use this information, and with which third parties we have shared this information within the past twelve months.
                    </p>
                    <table border="1" cellPadding={{cellPadding: "3px"}}>
                      <thead>
                        <th>Categories of Information Collected</th>
                        <th>Sources of Information</th>
                        <th>How we use this information</th>
                        <th>Third parties to which we have disclosed this information</th>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Identifiers</td>
                          <td>Directly from you</td>
                          <td>
                            To fulfill our obligations to you as presented when you provided the information<br />
                            To notify you about changes to our site<br />
                            For marketing and advertising purposes
                          </td>
                          <td>Service provider partners</td>
                        </tr>
                        <tr>
                          <td>Customer Records Personal Information</td>
                          <td>Directly from you</td>
                          <td>To fulfill our obligations to you as presented when you provided the information</td>
                          <td>Service provider partners</td>
                        </tr>
                        <tr>
                          <td>Commercial information</td>
                          <td>Directly from you</td>
                          <td>To fulfill our obligations to you as presented when you provided the information
                    To enhance and optimize your personal experience</td>
                          <td>Service provider partners</td>
                        </tr>
                        <tr>
                          <td>Internet or electronic network activity information</td>
                          <td>Directly from you
                    Device technologies</td>
                          <td>To fulfill our obligations to you as presented when you provided the information
                    To enhance and optimize your personal experience
                    To comply with security, fraud, and legal requirements</td>
                          <td>Service provider partners</td>
                        </tr>
                        <tr>
                          <td>Geolocation data</td>
                          <td>Directly from you
                    Device technologies</td>
                          <td>To fulfill our obligations to you as presented when you provided the information
                    To enhance and optimize your personal experience</td>
                          <td>Service provider partners</td>
                        </tr>
                        <tr>
                          <td>Professional or employment information</td>
                          <td>Directly from you</td>
                          <td>To fulfill our obligations to you as presented when you provided the information
                    To enhance and optimize your personal experience</td>
                          <td>Service provider partners</td>
                        </tr>
                      </tbody>
                    </table>
                    <p>
                      To exercise your right to make requests regarding your personal information, please contact us via email at support@casesmatter.com, or by mail at CasesMatter, LLC, 4301 Main St., Parsons, Kansas 67357. We may take steps to verify your identity before providing the requested information. 
                    </p>
                    <h3 id="opt10">9. Updates to This Policy</h3>
                    <p>
                      We may update this policy from time to time. If any updates are made to this policy, they will be posted here. Additionally, if material changes are made to this policy, we will notify you via a notice to this Site, and registered users will receive an additional email. Please check this page frequently for updates.
                    </p>
                    <h3 id="opt11">10. Contact Us</h3>
                    <p>
                      If you have any questions, comments, or concerns about this Privacy Policy or our services, please contact us via email at support@casesmatter.com, or by mail at 4301 Main St., Parsons, Kansas 67357.
                    </p>
                  </div>
                </div>
                {/*  /.tab-content */}
              </div>
            </div>
          </ScrollspyNav>
        </div>
      </div>

      <footer className="theme-footer-one pt-130 md-pt-70">
        <div className="top-footer">
          <div className="container">
            <Footer />
          </div>
          {/* /.container */}
        </div>
        {/* /.top-footer */}

        <div className="container">
          <div className="bottom-footer-content">
            <CopyRight />
          </div>
          {/*  /.bottom-footer */}
        </div>
      </footer>
      {/* /.theme-footer-one */}
    </div>
  );
};

export default PrivacyPolicy;