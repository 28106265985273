import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Header from "../../components/Header";
import HeroBanner from "../../components/HeroBanner";
import Footer from "../../components/Footer";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

const Main = () => {
  return (
    <div className="main-page-wrapper p0">
      <Helmet>
        <title>CasesMatter</title>
      </Helmet>
      {/* End Page SEO Content */}

      <Header />
      {/* End HeaderFour */}

      <HeroBanner />
      {/* End Hero Banner Four */}

      {/* =============================================
            Fancy Feature Twenty Three
        ==============================================  */}
      <div className="fancy-feature-twentyThree pt-170 md-pt-100" id="product">
        <div className="container">
          <div
            className="title-style-nine text-center pb-180 md-pb-100"
            data-aos="fade-up"
            data-aos-duration="1200"
          >
            <h6>Our Products</h6>
            <h2>
              CasesMatter delivers Products for Law Firms{" "}Designed by a{" "}
              <span>
                 Law Firm <img src="images/shape/192.svg" alt="shape" />
              </span>
            </h2>
            <p>
              Our online matter management platform allows small law firms to manage their practice.
            </p>            
          </div>
          {/* End title */}

          <div className="block-style-twentyThree">
            <div className="row align-items-center">
              <div
                className="col-lg-6 order-lg-last ms-auto"
                data-aos="fade-left"
                data-aos-duration="1200"
              >
                <div className="screen-container ms-auto">
                  <div
                    className="oval-shape"
                    style={{ background: "#738B85" }}
                  ></div>
                  <div
                    className="oval-shape"
                    style={{ background: "#FBF3EC" }}
                  ></div>
                  <img
                    src="images/assets/screen_18.png"
                    alt=""
                    className="shapes shape-one"
                  />
                </div>
                {/* /.screen-container */}
              </div>
              <div
                className="col-lg-5 order-lg-first"
                data-aos="fade-right"
                data-aos-duration="1200"
              >
                <div className="text-wrapper" id="mattermanagement" style={{scrollMarginTop: "6em"}}>
                  <h6>Matter Management</h6>
                  <h3 className="title">Digital Mastery: More than file management</h3>
                  <p>
                    Empower your small law firm with efficient matter management. Seamlessly organize, track, 
                    and navigate through your legal matters, ensuring a streamlined workflow and enhanced 
                    client service.
                  </p>
                </div>
                {/*  /.text-wrapper */}
              </div>
            </div>
          </div>
          {/* /.block-style-twentyThree */}

          <div className="block-style-twentyThree">
            <div className="row">
              <div className="col-lg-6">
                <div
                  className="screen-container me-auto"
                  data-aos="fade-right"
                  data-aos-duration="1200"
                >
                  <div
                    className="oval-shape"
                    style={{ background: "#DAD4C6" }}
                  ></div>
                  <div
                    className="oval-shape"
                    style={{ background: "#7FD1AE" }}
                  ></div>
                  <img
                    src="images/assets/screen_19.png"
                    alt="screen"
                    className="shapes shape-two"
                  />
                </div>
                {/* /.screen-container */}
              </div>
              <div
                className="col-lg-5 ms-auto"
                data-aos="fade-left"
                data-aos-duration="1200"
              >
                <div className="text-wrapper" id="tasking" style={{scrollMarginTop: "6em"}}>
                  <h6>Tasking</h6>
                  <h3 className="title">Task Precision: Beyond simple to-do lists</h3>
                  <p>
                    Prioritize and manage tasks within your legal matters. 
                    Stay on top of deadlines, assignments, and client needs with our 
                    intuitive task management feature, designed to enhance efficiency 
                    for small law firms.
                  </p>
                </div>
                {/* /.text-wrapper */}
              </div>
            </div>
          </div>
          {/* /.block-style-twentyThree */}

          <div className="block-style-twentyThree">
            <div className="row">
              <div
                className="col-lg-6 order-lg-last ms-auto"
                data-aos="fade-left"
                data-aos-duration="1200"
              >
                <div className="screen-container ms-auto">
                  <div
                    className="oval-shape"
                    style={{ background: "#33675F" }}
                  ></div>
                  <div
                    className="oval-shape"
                    style={{ background: "#CD7F00" }}
                  ></div>
                  <img
                    src="images/assets/screen_20.png"
                    alt="screen"
                    className="shapes shape-three"
                  />
                </div>
                {/* /.screen-container */}
              </div>
              <div
                className="col-lg-5 order-lg-first"
                data-aos="fade-right"
                data-aos-duration="1200"
              >
                <div className="text-wrapper" id="billing" style={{scrollMarginTop: "6em"}}>
                  <h6>Billing</h6>
                  <h3 className="title">
                    Task-Based Invoicing for Precision and Reduced Discounts
                  </h3>
                  <p>
                    Maximize revenue and client satisfaction with our integrated billing. 
                    Easily track task time and streamline invoicing for accurate and 
                    efficient financial management for your legal practice.
                  </p>
                </div>
                {/* /.text-wrapper */}
              </div>
            </div>
          </div>
          {/* /.block-style-twentyThree */}
          
        </div>
      </div>
      {/* /.fancy-feature-twentyThree */}

      <div className="fancy-feature-nine">
        <img
          src="images/shape/107.svg"
          alt="shape"
          className="shapes shape-one"
        />
        <img
          src="images/shape/108.svg"
          alt="shape"
          className="shapes shape-two"
        />
        <img
          src="images/shape/109.svg"
          alt="shape"
          className="shapes shape-three"
        />
        <img
          src="images/shape/110.svg"
          alt="shape"
          className="shapes shape-four"
        />
        <img
          src="images/shape/111.svg"
          alt="shape"
          className="shapes shape-five"
        />
        <img
          src="images/shape/112.svg"
          alt="shape"
          className="shapes shape-six"
        />

        <div className="container" id="whyus" style={{scrollMarginTop: "6em"}}>
          <div className="title-style-five text-center mb-60 md-mb-30">
            <h6>Why would you choose us?</h6>
            <h2>
              <span>3 simple & straight forward reasons.</span>
            </h2>
          </div>
          {/* End title */}

          <div className="row justify-content-center">
              <div
                className="col-lg-4 col-md-6"
                key="1"
                data-aos="fade-up"
                data-aos-duration="1200"
              >
                <div className="block-style-fourteen">
                  <div className="illustration">
                    <img src="images/assets/ils_17.svg" alt="icon" />
                  </div>
                  <div className="title">Save Time</div>
                  <p className="font-rubik">Stop searching for the file. Everyone has all the files all the time.</p>
                </div>
                {/* /.block-style-fourteen */}
              </div>
              <div
                className="col-lg-4 col-md-6"
                key="2"
                data-aos="fade-up"
                data-aos-duration="1200"
              >
                <div className="block-style-fourteen">
                  <div className="illustration">
                    <img src="images/assets/ils_13.svg" alt="icon" />
                  </div>
                  <div className="title">Clean Up Your Calendar</div>
                  <p className="font-rubik">Deadlines and reminders become tasks, read your calendar again.</p>
                </div>
                {/* /.block-style-fourteen */}
              </div>
              <div
                className="col-lg-4 col-md-6"
                key="3"
                data-aos="fade-up"
                data-aos-duration="1200"
              >
                <div className="block-style-fourteen">
                  <div className="illustration">
                    <img src="images/assets/ils_20.svg" alt="icon" />
                  </div>
                  <div className="title">Gain Control of Your Matters</div>
                  <p className="font-rubik">Everything in one place, accessible to everyone, anytime, everytime.</p>
                </div>
                {/* /.block-style-fourteen */}
              </div>
          </div>
        </div>
      </div>
      {/* /.fancy-feature-nine */}

      {/* 
     =============================================
				Fqa Text Block
		============================================== */}
      <div className="fancy-text-block-six overflow-hidden mt-250 md-mt-200">
        <div className="container">
          <div className="row align-items-center">
            <div
              className="col-lg-6"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              <div className="title-style-five mb-35">
                <h6>Why choose us</h6>
                <h2>
                  <span>Why you should</span> choose us?
                </h2>
              </div>
              {/* End title */}
              <div className="accordion-style-two pe-5">
                <div className="faq-wrapper">
                  <Accordion preExpanded={["a"]} allowZeroExpanded>
                    <AccordionItem className="card" key="1" uuid="a">
                      <AccordionItemHeading className="card-header">
                        <AccordionItemButton>
                          <h5 className="mb-0">
                            <button className="btn btn-link">Your Data is Your Data</button>{" "}
                          </h5>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      {/* Accordion Heading */}
                      <AccordionItemPanel className="card-body fadeInUp">
                        <p>
                          We believe that your data is your data and our technology powers your data. 
                          From the beginning, our technology has been designed to allow you to keep your 
                          data with us, host it yourself, or host with a another cloud provider of your 
                          choosing.
                        </p>
                      </AccordionItemPanel>
                      {/* Accordion Body Content */}
                    </AccordionItem>
                    <AccordionItem className="card" key="2" uuid="b">
                      <AccordionItemHeading className="card-header">
                        <AccordionItemButton>
                          <h5 className="mb-0">
                            <button className="btn btn-link">Our Technology Features</button>{" "}
                          </h5>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      {/* Accordion Heading */}
                      <AccordionItemPanel className="card-body fadeInUp">
                        <p>
                        CasesMatter was developed in a law office to be different, this begins with 
                        our technology. CasesMatter runs with WebAssembly (WASM) on Microsoft's Azure
                        infrastructure designed to support hosted data or user hosted data. This allows
                        our customers to choose if CasesMatter hosts their data or if the customer
                        hosts their own data.
                        </p>
                      </AccordionItemPanel>
                      {/* Accordion Body Content */}
                    </AccordionItem>
                  </Accordion>
                </div>
              </div>
            </div>

            <div
              className="col-lg-6 col-md-8 m-auto"
              data-aos="fade-left"
              data-aos-duration="1200"
            >
              <div className="illustration-holder d-flex justify-content-end md-mt-50">
                <img src="images/media/img_58.png" alt="media" />
                <img
                  src="images/shape/113.svg"
                  alt="media"
                  className="shapes shape-one"
                />
              </div>
              {/* /.illustration-holder */}
            </div>
          </div>
        </div>
      </div>
      {/* /.why should you choose us */}

      {/* 
     =============================================
				Pricing Section Three
		============================================== */}
      <div className="pricing-section-three mt-250 mb-200 md-mt-150 md-mb-150">
        <img
          src="images/shape/107.svg"
          alt="shape"
          className="shapes shape-one"
        />
        <img
          src="images/shape/108.svg"
          alt="shape"
          className="shapes shape-two"
        />
        <img
          src="images/shape/109.svg"
          alt="shape"
          className="shapes shape-three"
        />
        <img
          src="images/shape/110.svg"
          alt="shape"
          className="shapes shape-four"
        />
        <img
          src="images/shape/111.svg"
          alt="shape"
          className="shapes shape-five"
        />
        <img
          src="images/shape/112.svg"
          alt="shape"
          className="shapes shape-six"
        />

        <div className="container" id="pricing" style={{scrollMarginTop: "6em"}}>
          <div className="title-style-five text-center mb-50 md-mb-40">
            <h6>Our Pricing</h6>
            <div className="row">
              <div className="col-lg-9 m-auto">
                <h2>Reasonable pricing for Small Law, simple signup</h2>
              </div>
            </div>
          </div>

          <div className="pricing-table-area-three">
          <div className="row justify-content-center">
            <div
              className="col-lg-4 col-md-6"
              key="1"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="0"
            >
              <div className={`pr-table-wrapper skew-right`}>
                <div className={`pack-name font-slab pc1`}>
                  <span>User Pricing</span>
                </div>
                <div className="price font-slab">$25</div>
                <p className="user-condition">per user/mo.</p>
                <img src="images/shape/114.svg" alt="shape" className="line" />
                <p>
                  You pay $25 per user per month.<br /><br />
                </p>
                <ul>
                  <li className="">
                    Matter Management
                  </li>
                  <li className="">
                    Tasking
                  </li>
                  <li className="">
                    Billing
                  </li>
                </ul>
                <a href={process.env.REACT_APP_CONTROL_URL} className="subscribe-btn font-rubik">
                  Join Us
                </a>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6"
              key="2"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="100"
            >
              <div className={`pr-table-wrapper skew-left`}>
                <div className={`pack-name font-slab pc2`}>
                  <span>Database Pricing</span>
                </div>
                <div className="price font-slab">$25</div>
                <p className="user-condition">per Database/mo.</p>
                <img src="images/shape/114.svg" alt="shape" className="line" />
                <p>
                  At least one database per firm.<br /><br />
                </p>
                <ul>
                  <li className="">
                    Hosted with Us
                  </li>
                </ul>
                <p>- Or -</p>
                <ul>
                  <li className="">
                    Hosted by You
                  </li>
                </ul>
                <a href={process.env.REACT_APP_CONTROL_URL} className="subscribe-btn font-rubik">
                  Join Us
                </a>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6"
              key="3"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="200"
            >
              <div className={`pr-table-wrapper skew-right`}>
                <div className={`pack-name font-slab pc3`}>
                  <span>Hosted Database</span>
                </div>
                <div className="price font-slab">$5</div>
                <p className="user-condition">per GB/mo.</p>
                <img src="images/shape/114.svg" alt="shape" className="line" />
                <p>
                  You opt to host yourself or with us.<br /><br />
                </p>
                <ul>
                  <li className="">
                    Hosted on Microsoft Azure
                  </li>
                  <li className="">
                    Export your Data Anytime
                  </li>
                  <li className="disable">
                    File Storage (not included)
                  </li>
                </ul>
                <a href={process.env.REACT_APP_CONTROL_URL} className="subscribe-btn font-rubik">
                  Join Us
                </a>
              </div>
            </div>
        </div>
          </div>
          {/* /.pricing-table-area-three */}
        </div>
        {/* ./container */}
      </div>
      {/* /.pricing-section-three */}

      {/* 
     =============================================
			Call To Action
		============================================== */}
      <div className="fancy-short-banner-five pt-150 pb-150 md-pt-100 md-pb-100">
        <div className="container">
      <div className="title-style-five text-center">
        <h2>
          <span>Sign up & get started</span>
        </h2>
      </div>
      <div className="row">
        <div className="col-xl-7 col-lg-8 col-md-9 m-auto">
          <p className="font-rubik sub-heading">
            Click the button below and begin by creating your account today.
          </p>
        </div>
        {/* End .col */}
      </div>
      {/* End .row */}
      <div className="row">
        <div className="col-xl-7 col-lg-8 col-md-10 m-auto">
          <div class="row justify-content-center align-self-center mb-4">
            <Link to={process.env.REACT_APP_CONTROL_URL} className="theme-btn-five">
              Join Us
            </Link>
          </div>
          <div class="row justify-content-center">
            <div className="info-text" class="justify-content-center align-self-center info-text">
              Already a member? <Link to={process.env.REACT_APP_CLIENT_URL}>Sign in.</Link>
            </div>
          </div>
        </div>
        {/* End .col */}
      </div>
      {/* End .row */}
        </div>
      </div>
      {/* End .fancy-short-banner-five */}

      {/* 
     =============================================
			Footer Four
		============================================== */}
      <footer className="theme-footer-four">
        <div className="top-footer">
          <div className="container">
            <Footer />
          </div>
          {/* /.container */}
        </div>

        <div className="container">
          <div className="bottom-footer-content">
            <p>
              Copyright @{new Date().getFullYear()}{" "}
              <a
                href="https://casesmatter.com"
              >
                CasesMatter
              </a>,{" "}
              LLC
            </p>
          </div>
          {/* /.bottom-footer */}
        </div>
      </footer>
      {/* /.theme-footer-four */}
    </div>
  );
};

export default Main;
