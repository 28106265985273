import React, { useState } from "react";
import "react-pro-sidebar/dist/css/styles.css";
import {
  ProSidebar,
  SidebarHeader,
  Menu,
  MenuItem,
  SubMenu,
  SidebarContent,
} from "react-pro-sidebar";

const MegaMenuMobile = () => {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);

  return (
    <div className="mega-menu-wrapper">
      <div className="mega-swicher"></div>
      <div className="mob-header multi-mob-header">
        <button className="toggler-menu" onClick={handleClick}>
          <div className={click ? "active" : ""}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </button>
      </div>

      <ProSidebar className={click ? "mega-mobile-menu menu-open" : "mega-mobile-menu"}>
        <SidebarHeader>
          <div className="logo position-static">
            <a href="index.html">
              <img src="/images/logo/CasesMatter-Logo-White.svg" alt="home-demo" />
            </a>
          </div>
          <div className="fix-icon text-dark" onClick={handleClick}>
            <img src="images/icon/close-w.svg" alt="icon" />
          </div>
          {/* Mobile Menu close icon */}

          {/* End logo */}
        </SidebarHeader>
        <SidebarContent>
          <Menu>
            <MenuItem>
              <a href="/#">Home</a>
            </MenuItem>

            <SubMenu title="Product">
              <MenuItem>
                <a href="/#mattermanagement">Matter Management</a>
              </MenuItem>
              <MenuItem>
                <a href="/#tasking">Tasking</a>
              </MenuItem>
              <MenuItem>
                <a href="/#billing">Billing</a>
              </MenuItem>
            </SubMenu>

            <MenuItem>
              <a href="/#whyus">Why CasesMatter?</a>
            </MenuItem>
            <MenuItem>
              <a href="/#pricing">Pricing</a>
            </MenuItem>
            <MenuItem>
              <a href="/contact">Contact Us</a>
            </MenuItem>
            <MenuItem>
              <a href={process.env.REACT_APP_CONTROL_URL}>Join Us</a>
            </MenuItem>

            <MenuItem>
              <a href={process.env.REACT_APP_CONTROL_URL}>Portal</a>
            </MenuItem>
            <MenuItem>
              <a href={process.env.REACT_APP_CLIENT_URL}>CasesMatter</a>
            </MenuItem>
          </Menu>
        </SidebarContent>
      </ProSidebar>
    </div>
  );
};

export default MegaMenuMobile;
